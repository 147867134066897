import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from 'assets/logos/Logo-Gunmetal.png'
import { QRCodeSVG } from "qrcode.react";

const GiftCertificate = () => {
  const certificateRef = useRef(null);

  const valueToEncode = "ABC123XYZ";
  // Function to generate the PDF
  const generatePDF = () => {
    const input = certificateRef.current;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px', 'a4', false);

      // Adjust dimensions for PDF layout
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('gift-certificate.pdf');
    });
  };




  return (
    <div>
      <div
        ref={certificateRef}
        style={{
          padding: '20px',
          backgroundColor: '#f7f7f7',
          color: '#4B0082', // Deep purple text color
          borderRadius: '10px',
          textAlign: 'center',
          maxWidth: '800px',
          margin: '20px auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <img src={logo} alt="Island Destinations Logo" style={{ width: '40%' }} />
        <h1
          style={{
            fontSize: '50px',
            color: '#FF6347', // Vibrant tomato color
            fontFamily: "'Brush Script MT', cursive", // Fancy font
          }}
        >
          Congratulations!
        </h1>
        <p style={{ fontSize: '24px' }}>You've received a</p>
        <h2
          style={{
            fontSize: '36px',
            // background: 'linear-gradient(45deg, #FF69B4, #8A2BE2)',
            WebkitBackgroundClip: 'text',
            color: '#02b9d3',
          }}
        >
          $100 Gift Certificate
        </h2>
        <div>
        <p style={{ fontSize: '18px' }}>From: Your Company Name</p>
        <p style={{ fontSize: '18px' }}>To: John Doe</p>
        {/* <QRCodeSVG value={valueToEncode} size={100} /> */}
        </div>
        

        <div
          style={{
            marginTop: '40px',
            padding: '20px',
            backgroundColor: '#FF4500', // Vibrant orange-red background
            color: 'white',
            borderRadius: '10px',
          }}
        >
          <h3>Certificate Code: ABC123XYZ</h3>
          <p style={{ fontSize: '14px' }}>
            Redeemable at all our stores until December 31, 2024.
          </p>
        </div>
        
        <div
          style={{
            marginTop: '20px',
            fontStyle: 'italic',
            fontSize: '16px',
            color: '#696969', // Dim gray color for terms
          }}
        >
          * Terms and conditions apply.
        </div>
      </div>

      <button
        onClick={generatePDF}
        style={{
          display: 'block',
          margin: '20px auto',
          padding: '10px 20px',
          fontSize: '18px',
          backgroundColor: '#32CD32', // LimeGreen color
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Download as PDF
      </button>
    </div>
  );
};

export default GiftCertificate;
