import React from "react";
import "./promo.css";
import bgImage from "assets/images/beach.JPG";
import fall from "assets/images/waterfall.jpg";
const Promotion = () => {
  return (
    <div className="bg-white">
    {/* Hero Section */}
    <header className="bg-hero-pattern bg-cover text-white py-24">
      <div className="container mx-auto text-center animate-fadeIn">
        <h1 className="text-5xl font-bold animate-slideInDown">Island Destinations App</h1>
        <p className="mt-4 text-xl animate-slideInUp">Jamaica’s Premier Travel Guide</p>
        <button className="mt-8 bg-green-500 text-white py-3 px-6 rounded-lg hover:bg-green-600 transition animate-bounce">
          Advertise Your Business
        </button>
        <p className="mt-4 text-lg animate-slideInUp">Reach Thousands of Tourists and Locals Exploring the Island</p>
      </div>
    </header>

    {/* Welcome Section */}
    <section className="py-12 bg-gray-50 text-center">
      <div className="container mx-auto animate-fadeIn">
        <h2 className="text-3xl font-bold">Welcome to Island Destinations</h2>
        <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
          Island Destinations is Jamaica’s go-to travel app, connecting visitors with the island’s most exciting experiences, accommodations, dining, and attractions. Owned by Travel App Jamaica Limited, our app is the ultimate guide for travelers looking to explore everything Jamaica has to offer. Now, your business can be a part of this journey by advertising on our platform.
        </p>
      </div>
    </section>

    {/* Why Advertise Section */}
    <section className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold">Why Advertise with Us?</h2>
        <div className="mt-8">
          <div className="flex justify-around text-left">
            <div className="w-1/3">
              <h3 className="text-xl font-semibold">Targeted Audience</h3>
              <p className="mt-2 text-gray-600">
                Island Destinations connects you directly with a highly targeted audience of travelers and locals actively searching for places to visit, stay, eat, and enjoy.
              </p>
            </div>
            <div className="w-1/3">
              <h3 className="text-xl font-semibold">Boost Visibility</h3>
              <p className="mt-2 text-gray-600">
                With thousands of daily active users, your business will be front and center, attracting customers looking for the best experiences.
              </p>
            </div>
            <div className="w-1/3">
              <h3 className="text-xl font-semibold">Increase Credibility</h3>
              <p className="mt-2 text-gray-600">
                Being listed on a trusted platform boosts your brand's credibility and trust with potential customers.
              </p>
            </div>
          </div>

          <div className="flex justify-around mt-8 text-left">
            <div className="w-1/3">
              <h3 className="text-xl font-semibold">Cost-Effective Advertising</h3>
              <p className="mt-2 text-gray-600">
                Our flexible packages ensure your advertising dollars work for you, reaching the right people at the right time.
              </p>
            </div>
            <div className="w-1/3">
              <h3 className="text-xl font-semibold">Analytics & Insights</h3>
              <p className="mt-2 text-gray-600">
                Track your ad performance with real-time data, helping you optimize your campaigns and see results fast.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Services Section */}
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold animate-slideInLeft">Advertise with Us</h2>
        <p className="mt-4 text-lg text-gray-600 animate-slideInRight">
          Focusing on quality, we help you maintain customer trust while increasing visibility.
        </p>
        <div className="flex justify-around mt-12">
          <div className="w-1/4 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 animate-fadeIn">
            <img src="https://via.placeholder.com/150" alt="Basic Listing" className="rounded-lg mb-4" />
            <h3 className="text-xl font-semibold">Basic Listing</h3>
            <p className="mt-4 text-gray-500">Annual subscription for JMD 80,000. Includes full business profile with 24/7 visibility.</p>
          </div>
          <div className="w-1/4 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 animate-fadeIn">
            <img src="https://via.placeholder.com/150" alt="Featured Listing" className="rounded-lg mb-4" />
            <h3 className="text-xl font-semibold">Featured Listing</h3>
            <p className="mt-4 text-gray-500">Get top placement at JMD 5,500/month with social media boosts and detailed analytics.</p>
          </div>
          <div className="w-1/4 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 animate-fadeIn">
            <img src="https://via.placeholder.com/150" alt="Banner Ads" className="rounded-lg mb-4" />
            <h3 className="text-xl font-semibold">Banner Ads</h3>
            <p className="mt-4 text-gray-500">Reach users with high-visibility banners, promoting your business effectively.</p>
          </div>
          <div className="w-1/4 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 animate-fadeIn">
            <img src="https://via.placeholder.com/150" alt="Push Notifications" className="rounded-lg mb-4" />
            <h3 className="text-xl font-semibold">Push Notifications</h3>
            <p className="mt-4 text-gray-500">Send special offers directly to users' devices and drive conversions instantly.</p>
          </div>
        </div>
      </div>
    </section>

    {/* Testimonials Section */}
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold animate-fadeIn">What Our Partners Say</h2>
        <p className="mt-4 text-lg text-gray-600 animate-fadeIn">
          Trusted service for your various needs
        </p>
        <div className="flex justify-around mt-12">
          <div className="w-1/3 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 animate-slideInLeft">
            <img src="https://via.placeholder.com/150" alt="Negril Boutique Hotel" className="rounded-lg mb-4" />
            <p className="italic">"Since joining Island Destinations, we’ve seen a 30% increase in direct bookings. It’s a game-changer!"</p>
            <h3 className="mt-4 text-lg font-semibold">Negril Boutique Hotel</h3>
          </div>
          <div className="w-1/3 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 animate-slideInRight">
            <img src="https://via.placeholder.com/150" alt="Montego Bay Tours" className="rounded-lg mb-4" />
            <p className="italic">"Our visibility has never been better! We love the easy-to-use platform and the results we’ve achieved."</p>
            <h3 className="mt-4 text-lg font-semibold">Montego Bay Tours</h3>
          </div>
        </div>
      </div>
    </section>

    {/* CTA Section */}
    <section className="py-12 bg-teal-500 text-white text-center">
      <div className="container mx-auto animate-fadeIn">
        <h2 className="text-3xl font-bold animate-slideInUp">See How We Can Help Your Business Grow</h2>
        <p className="mt-4 text-lg">Learn more about our advertising options and take the next step.</p>
        <button className="mt-8 bg-white text-teal-500 py-3 px-6 rounded-lg hover:bg-gray-100 transition animate-pulse">
          Get Started Today
        </button>
      </div>
    </section>

    {/* Footer */}
    <footer className="py-8 bg-gray-800 text-white text-center">
      <div className="container mx-auto">
        <p>&copy; {new Date().getFullYear()} Travel App Jamaica Limited. All Rights Reserved.</p>
        <div className="mt-4">
          <a href="https://instagram.com" className="mr-4">Instagram</a>
          <a href="https://facebook.com" className="mr-4">Facebook</a>
          <a href="https://tiktok.com">TikTok</a>
        </div>
      </div>
    </footer>
  </div>
  );
};

export default Promotion;
