import { motion } from 'framer-motion';
import HeroSection from 'components/HeroSection';
import Destinations from 'components/Destinations';


const pageVariants = {
  initial: { opacity: 0, y: 100 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -100 },
};

function Home() {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={{ duration: 0.5 }}
    >
      <HeroSection />
      <Destinations />
    </motion.div>
  );
}

export default Home;
