import DestinationCard from "components/DestinationCard";

function Destinations() {
  const destinationData = [
    { id: 1, image: '/images/montego-bay.jpg', title: 'Montego Bay', location: 'Montego Bay, Jamaica', price: 120 },
    { id: 2, image: '/images/ocho-rios.jpg', title: 'Ocho Rios', location: 'Ocho Rios, Jamaica', price: 150 },
  ];

  return (
    <div className="container mx-auto px-6 py-10">
      <h1 className="text-4xl font-bold mb-6">Top Destinations</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {destinationData.map((dest) => (
          <DestinationCard
            key={dest.id}
            image={dest.image}
            title={dest.title}
            location={dest.location}
            price={dest.price}
          />
        ))}
      </div>
    </div>
  );
}

export default Destinations;
