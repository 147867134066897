function HeroSection() {
    return (
      <section className="bg-hero bg-cover bg-center h-screen flex items-center justify-center">
        <div className="text-center text-white">
          <h1 className="text-5xl font-bold mb-4">Explore Jamaica</h1>
          <p className="text-xl mb-8">Find the perfect destination for your trip</p>
          <input
            type="text"
            placeholder="Search destinations..."
            className="px-4 py-2 rounded-lg w-1/2"
          />
        </div>
      </section>
    );
  }
  
  export default HeroSection;
  