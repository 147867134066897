import { motion } from 'framer-motion';

function DestinationCard({ image, title, location, price }) {
  return (
    <motion.div whileHover={{ scale: 1.05 }} className="w-80 bg-white shadow-md rounded-lg overflow-hidden">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h2 className="font-bold text-xl">{title}</h2>
        <p className="text-gray-700">{location}</p>
        <p className="text-gray-900 font-semibold">${price}/night</p>
      </div>
    </motion.div>
  );
}

export default DestinationCard;
