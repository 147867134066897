import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import DefaultFooter from "examples/Footers/CenteredFooter";

// Images
import app_screen from "assets/images/id-app-screen.png";
import appStore from "assets/images/app-store.webp";
import playStore from "assets/images/play-store.webp";
// import appIcon from "assets/images/app-icon.jpg";

const index = () => {
  return (
    <Container>
      <Grid item xs={12} md={4} sx={{ mt: { xs: 2, md: 0 } }}>
        <h1 style={{ textAlign: "center" }}>Island Destinations</h1>
        <h4 style={{ textAlign: "center" }}>Destinations within your reach!</h4>
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
        <img src={app_screen} alt="" style={{ width: "70%" }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <h3 style={{ textAlign: "center" }}>Download from</h3>
      </Grid>
      <Grid item xs={12} md={4} style={{ alignItems: "center", justifyContent: "space-between" }}>
        <a href="https://apps.apple.com/us/app/island-destinations/id1549344071">
          <img src={appStore} alt="" style={{ width: "50%" }} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.islanddestinations">
          <img src={playStore} alt="" style={{ width: "50%" }} />
        </a>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <DefaultFooter />
    </Container>
  );
};

export default index;
